import React, { useContext, useEffect, useState } from "react";
import { Layout } from "layout";
import { Card, Button } from "lib";
import {
  CreateRegistrationCommand,
  RegistrationsClient,
  RegistrationPeriodsClient,
} from "@api";
import { Divider, Form, Grid, Input, message, Modal, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { showError } from "@action";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";
import { Store } from "@store";

const RegisterPage: React.FC<{ passphrase: string }> = ({ passphrase }) => {
  const { setLang } = useContext(Store);

  const cookieName = "LoginToken";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

  const [form] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();

  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [state, setState] = useState({
    hasEmailNotification: false,
    hasConfirmCode: false,
  });
  const [clientGroupLang, setClientGroupLang] = useState("en");

  const [cred, setCred] = useState({
    username: "",
    password: "",
  });

  const registrationsClient = new RegistrationsClient();
  const registrationPeriodsClient = new RegistrationPeriodsClient();

  const { sm } = Grid.useBreakpoint();

  const layout = {
    labelCol: { xl: 12, sm: 12, xs: 24 },
    wrapperCol: { xl: 12, sm: 12, xs: 24 },
  };

  async function fetchRegistrationPeriod() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticLoadingMessage" />
    );
    try {
      const res = await registrationPeriodsClient.get(passphrase);
      setState({
        hasEmailNotification: res.hasEmailNotification as boolean,
        hasConfirmCode: res.hasConfirmCode as boolean,
      });

      const userLang = res.clientGroup?.preferredLanguageId;
      if (userLang) {
        setClientGroupLang(userLang);
        if (!sessionStorage.getItem("lang")) setLang(userLang);
      }
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function registerNewClientUser() {
    // setState({
    //   ...state,
    //   loading: true,
    // });
    const hide = messageApi.loading(
      <FormattedMessage id="StaticCreatingMessage" />
    );

    const values = await form.validateFields();
    const emailValue = values.email == "" ? undefined : values.email;
    const confirmCodeValue = values.confirmCode == "" ? undefined : values.confirmCode;
    try {
      const res = await registrationsClient.create(
        new CreateRegistrationCommand({
          passphrase: passphrase,
          email: emailValue,
          confirmCode: confirmCodeValue,
        })
      );

      setCred({
        username: res.clientUser?.userName as string,
        password: res.clientUser?.defaultPassword as string,
      });
      if (res.loginToken != null)
        setCookie(cookieName, res.loginToken, { path: "/" });
      messageApi.success(<FormattedMessage id="StaticCreateSuccessMessage" />);
      setCreateModalVisible(true);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  const backToFrontPage = () => {
    setLang(clientGroupLang);
    navigate("/welcome/", {
      replace: true,
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  useEffect(() => {
    fetchRegistrationPeriod();
  }, []);

  return (
    <Layout title="Registration" AllowChangeLanguage={true} hidden>
      <Card style={{ maxWidth: "1000px", margin: "0px auto" }}>
        {sm ? (
          <Divider>
            <Typography.Title level={3}>
              <FormattedMessage id="RegistrationPeriodHeader" />
            </Typography.Title>
          </Divider>
        ) : (
          <Typography.Title
            level={3}
            style={{ margin: "16px auto", textAlign: "center" }}
          >
            <FormattedMessage id="RegistrationPeriodHeader" />
          </Typography.Title>
        )}
        <Form
          form={form}
          labelAlign="left"
          onFinish={registerNewClientUser}
          {...layout}
        >
          <Form.Item
            hidden={!state.hasEmailNotification}
            name="email"
            label={<FormattedMessage id="RegistrationPeriodEmail" />}
            rules={[
              {
                type: "email",
                message: (
                  <FormattedMessage id="RegistrationPeriodEmailTypeWarning" />
                ),
              },
              {
                required: state.hasEmailNotification,
                message: (
                  <FormattedMessage id="RegistrationPeriodEmailRequiredWarning" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            hidden={!state.hasConfirmCode}
            name="confirmCode"
            label={<FormattedMessage id="RegistrationPeriodConfirmCode" />}
            rules={[
              {
                type: "string",
                message: (
                  <FormattedMessage id="RegistrationPeriodConfirmCodeTypeWarning" />
                ),
              },
              {
                required: state.hasConfirmCode,
                message: (
                  <FormattedMessage id="RegistrationPeriodConfirmCodeRequiredWarning" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Typography.Paragraph
            hidden={state.hasEmailNotification}
            style={{ margin: "16px auto" }}
          >
            <FormattedMessage id="RegistrationPeriodPopupContent" />
          </Typography.Paragraph>
          <Form.Item
            wrapperCol={{ sm: 24 }}
            style={{
              justifyContent: "center",
              marginTop: "24px",
            }}
          >
            <Button block htmlType="submit">
              <FormattedMessage id="RegistrationPeriodSubmitButton" />
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        visible={createModalVisible}
        title={<FormattedMessage id="RegistrationPeriodPopupHeader" />}
        closable={false}
        onOk={backToFrontPage}
        footer={[
          <Button key="submit" type="primary" onClick={backToFrontPage}>
            <FormattedMessage id="RegistrationPeriodPopupProceedButton" />
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <Form layout="horizontal" {...formItemLayout}>
          <Typography.Paragraph style={{ textAlign: "center" }}>
            <FormattedMessage id="RegistrationPeriodPopupSubHeader" />
          </Typography.Paragraph>
          <Form.Item
            name="username"
            label={<FormattedMessage id="LoginInputLabel" />}
            initialValue=""
          >
            <Typography.Text copyable strong underline>
              {cred.username}
            </Typography.Text>
          </Form.Item>
          <Form.Item
            name="defaultPassword"
            label={<FormattedMessage id="LoginPasswordInputLabel" />}
            initialValue=""
          >
            <Typography.Text copyable strong underline>
              {cred.password}
            </Typography.Text>
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default RegisterPage;
